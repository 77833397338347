<template>
  <div class="content-ex">
    <div class="head">
      <p>兑换物品</p>
      <img
        @click="close"
        src="https://ppyos.xijiuyou.com/imgs/smartgame/close.png"
        alt=""
      />
    </div>
    <div class="card" :style="prizecontent.prizeType==1?'background: linear-gradient(180deg, #F1FAFF 0%, #FFFFFF 100%);':prizecontent.prizeType==2?'background: linear-gradient(180deg, #FFF9ED 0%, #FFFFFF 100%);':'background: linear-gradient(180deg, #EEEEEE 0%, #FFFFFF 100%);'">
      <div :style="prizecontent.prizeType==1?'background: url(https://ppyos.xijiuyou.com/imgs/smartgame/upcard-blue.png) 0% 0% / 100% 100% no-repeat;':prizecontent.prizeType==2?'background: url(https://ppyos.xijiuyou.com/imgs/smartgame/upcard-gold.png) 0% 0% / 100% 100% no-repeat;':'background: url(https://ppyos.xijiuyou.com/imgs/smartgame/upcard-black.png) 0% 0% / 100% 100% no-repeat;'">
        <p :style="prizecontent.prizeType==1?'background: #82b6ea;':prizecontent.prizeType==2?'background: #dda96c;':'background: rgba(255, 230, 174, 1);color: rgba(102, 96, 84, 1);'">{{prizecontent.cardProp.cardDesc}}</p>
        <p :style="prizecontent.prizeType==1?'color: #577897;':prizecontent.prizeType==2?'color: #794636':'color: rgba(255, 230, 174, 1);'">{{prizecontent.cardProp.total}}<img
                :src="prizecontent.prizeType==1?'https://ppyos.xijiuyou.com/imgs/smartgame/rmb-3.png':prizecontent.prizeType==2?'https://ppyos.xijiuyou.com/imgs/smartgame/rmb-1.png':'https://ppyos.xijiuyou.com/imgs/smartgame/rmb-2.png'"
                alt=""
              /></p>
      </div>
    </div>
    <div class="introduce">{{prizecontent.cardProp.title}}（{{prizecontent.cardProp.cardDesc}}额外收益/{{prizecontent.cardProp.duration}}天有效期）</div>
    <div class="explain">
      <p>兑换说明</p>
      <p v-html="prizecontent.goodsProps.tips"></p>
    </div>
    <div class="active">
      <p><span>{{prizecontent.price}}</span> <span>活跃值</span></p>
      <p v-if="prizecontent.price < huoyue" @click="exchange">立即兑换</p>
      <p v-else style="background: #DDDDDD;">活跃值不足</p>
    </div>
  </div>
</template>
<script>
import active from "../api/active";
export default {
  data() {
    return {
      id: 1,
      yue: false,
    };
  },
  props: {
    prizecontent:{
      type:Object
    },
    huoyue:{
      type:String
    }
  },
  methods: {
    exchange(){
      active.exchange({goodsId:this.prizecontent.goodsId}).then(res=>{
        if(res.status == 0){
          this.$emit("closeHandlesuc");
        }
      })
    },
    close() {
      this.$emit("closeHandle");
    },
  },
};
</script>
<style scoped>
.content-ex {
  position: fixed;
  bottom: 0;
  width: 100vw;
  background: #fff;
  border-radius: 20px 20px 0 0;
  padding-bottom: 18px;
}
.head {
  display: flex;
  justify-content: space-between;
  padding: 14px;
  align-items: center;
}
.head p {
  width: 100%;
  text-align: center;
  padding-left: 16px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.head img {
  width: 16px;
  height: 16px;
}
.card {
  width: calc(100% - 32px);
  margin: 0 auto;
  height: 113px;
  background: url("https://ppyos.xijiuyou.com/imgs/smartgame/bg-color-card.png")
    no-repeat;
  background-size: 100% 100%;
  padding-top: 18px;
  border-radius: 10px;
}
.card div {
  width: calc(100% - 113px);
  margin: 0 auto;
  height: 74px;
  background: url("https://ppyos.xijiuyou.com/imgs/smartgame/upcard-blue.png")
    no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card div p:nth-child(1) {
  width: 82px;
  height: 39px;
  background: #dda96c;
  border-radius: 4px;
  font-size: 25px;
  font-family: DINOffcPro-Black, DINOffcPro;
  font-weight: 900;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding-top: 4px;
  box-sizing: border-box;
  justify-content: center;
  margin-right: 35px;
}
.card div p:nth-child(2) {
  font-size: 25px;
  font-family: DINOffcPro-Black, DINOffcPro;
  font-weight: 900;
  color: #794636;
  display: flex;
  align-items: center;
}
.card div p:nth-child(2) img{
  width: 20px;
  height: 20px;
  margin-left: 5px;
  margin-top: -6px;
}
.introduce {
  margin-top: 10px;
  font-size: 16px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #444444;
}
.explain {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  text-align: left;
  padding: 0 18px;
  margin-top: 43px;
  margin-bottom: 37px;
}
.explain p{
  line-height: 25px;
}
.active {
  display: flex;
  justify-content: space-between;
  padding: 0 18px;
}
.active p {
  font-size: 20px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #ff5675;
  display: flex;
  white-space:nowrap;
  width: 50%;
  align-items: center;
}
.active p span:nth-child(2) {
  font-size: 14px;
  margin-left: 4px;
}
.active p:nth-child(2) {
  width: 100%;
  height: 48px;
  background: linear-gradient(90deg, #ff8c81 0%, #ff6c87 100%);
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
</style>