<template>
  <div class="content">
    <div class="active">
      <p>
        当前活跃值<img
          src="https://ppyos.xijiuyou.com/icon/fire-icon.png"
          alt=""
        />
      </p>
      <p>{{huoyue}}</p>
    </div>
    <div class="prize">兑换奖品</div>
    <div class="record" @click="going">兑换记录</div>
    <div class="center">
      <div class="center-list" @click="lottery(item)"  v-for="(item , index) in prize" :key="index">
        <div class="center-list-card" :style="item.prizeType==1?'':item.prizeType==2?'background: #fff9ed;':'background: #eeeeee;'">
          <div
            :style="
              item.prizeType==1?'background: url(https://ppyos.xijiuyou.com/imgs/smartgame/upcard-blue.png) 0% 0% / 100% 100% no-repeat;':item.prizeType==2?'background: url(https://ppyos.xijiuyou.com/imgs/smartgame/upcard-gold.png) 0% 0% / 100% 100% no-repeat;':'background: url(https://ppyos.xijiuyou.com/imgs/smartgame/upcard-black.png) 0% 0% / 100% 100% no-repeat;'
            "
          >
            <p :style="item.prizeType==1?'':item.prizeType==2?'background: #dda96c;':'background: rgba(255, 230, 174, 1);color: rgba(102, 96, 84, 1);'">{{item.cardProp.cardDesc}}</p>
            <p  :style="item.prizeType==1?'':item.prizeType==2?'color: #794636':'color: rgba(255, 230, 174, 1);'">
              <span>{{item.cardProp.total}}</span>
              <img
                :src="item.prizeType==1?'https://ppyos.xijiuyou.com/imgs/smartgame/rmb-3.png':item.prizeType==2?'https://ppyos.xijiuyou.com/imgs/smartgame/rmb-1.png':'https://ppyos.xijiuyou.com/imgs/smartgame/rmb-2.png'"
                alt=""
              />
            </p>
          </div>
          <p class="card" :style="item.prizeType==1?'':item.prizeType==2?'color: #794636 !important;':'color: #333333 !important;'">{{item.cardProp.duration}}天收益加成卡</p>
        </div>
        <p class="center-list-fire">
          {{item.price}}活跃值<img
            src="https://ppyos.xijiuyou.com/icon/fire-icon.png"
            alt=""
          />
        </p>
      </div>

    </div>
    <xwpopup
      :isshowpopup="lotterycard"
      @close="closeHandle()"
      :hiddenClose="true"
    >
      <card @closeHandle="closeHandle" @closeHandlesuc='closeHandlesuc' :huoyue='huoyue' :prizecontent='prizecontent'></card>
    </xwpopup>
    <xwpopup :isshowpopup="profit" @close="closeprofit()" :hiddenClose="false">
      <div class="profit">
        <img
          src="https://ppyos.xijiuyou.com/imgs/smartgame/success-buy.png"
          alt=""
        />
        <div>
          <img
            src="https://ppyos.xijiuyou.com/imgs/smartgame/upcard.png"
            alt=""
          />
          <p>{{prizecontent.cardProp?prizecontent.cardProp.title:''}}</p>
          <p>（{{prizecontent.cardProp?prizecontent.cardProp.cardDesc:''}}额外收益/{{prizecontent.cardProp?prizecontent.cardProp.duration:''}}天有效期）</p>
          <p @click="going">前往查看</p>
        </div>
      </div>
    </xwpopup>
  </div>
</template>
<script>
import card from "../../components/card.vue";
import xwpopup from "../../components/Popupbox.vue";
import active from "../../api/active";
import home from "../../api/home";
export default {
  components: {
    xwpopup,
    card,
  },
  data() {
    return {
      lotterycard: false,
      profit: false,
      huoyue:'',
      prize:[],
      prizecontent:{}
    };
  },
  mounted(){
    // this.huoyue = this.$route.query.huoy;
    this.list();
    this.actuserActiveValue();
  },
  methods: {
    list(){
      active.prizes().then(res=>{
        this.prize = res.data
      })
    },
    actuserActiveValue(){
      home.actuserActiveValue().then((res) => {
        this.huoyue = res.data;
      });
    },
    going(){
      this.$router.push({
        name:"Active"
      })
    },
    lottery(item) {
      this.prizecontent=item
      this.lotterycard = true;
    },
    closeHandle() {
      this.lotterycard = false;
    },
    closeHandlesuc(){
      this.lotterycard = false;
      this.profit = true;
      this.actuserActiveValue();
    },
    closeprofit() {
      this.profit = false;
    },
  },
};
</script>
<style scoped>
.content {
  width: 100%;
  min-height: 100vh;
  background: #ffffff;
  padding-top: 10px;
}
.active p {
  display: flex;
  justify-content: center;
  align-items: center;
}
.active p:nth-child(1) {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #7b3b00;
}
.active p img {
  width: 14px;
  height: 14px;
}
.active p:nth-child(2) {
  font-size: 28px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #7b3b00;
  margin-top: 8px;
}
.prize {
  margin-top: 27px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  padding-left: 12px;
  margin-bottom: 10px;
}
.record {
  position: fixed;
  right: 0;
  top: 30px;
  width: 71px;
  height: 26px;
  background: rgba(255, 227, 178, 0.49);
  border-radius: 20px 0px 0px 20px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #7b3b00;
  display: flex;
  align-items: center;
  justify-content: center;
}
.center {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 12px;
}
.center-list {
  width: calc(50% - 12px);
  text-align: center;
  margin-bottom: 20px;
}
.center-list-card {
  background: #f1faff;
  border-radius: 10px;
  padding: 8px 0 8px;
}
.center-list-card div {
  width: calc(100% - 15px);
  height: 56px;
  margin: 0 auto 0;
  display: flex;
  justify-content: center;
  padding: 0 8px;
  align-items: center;
}
.center-list-card p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #577897;
  margin-top: 13px;
}
.center-list-card p:nth-child(1) {
  width: 60px;
  height: 28px;
  background: #82b6ea;
  border-radius: 4px;
  font-size: 20px;
  font-family: DINOffcPro-Black, DINOffcPro;
  font-weight: 900;
  color: #ffffff;
  margin: 0;
  display: flex;
  padding-top: 3px;
  align-content: center;
  justify-content: center;
  margin-right: 10px;
}
.center-list-card p:nth-child(2) {
  font-size: 20px;
  font-family: DINOffcPro-Black, DINOffcPro;
  font-weight: 900;
  color: #577897;
  display: flex;
  align-items: center;
  margin: 0;
}
.center-list-card p:nth-child(2) span{
  display: flex;
  height: 18px;
  padding-top: 3px;
  align-items: center;
}
.center-list-card p:nth-child(2) img {
  width: 18px;
  height: 18px;
}
.center-list-card .card {
  font-size: 14px !important;
  font-family: PingFangSC-Regular, PingFang SC !important;
  font-weight: 400 !important;
  color: #577897 !important;
  justify-content: center;
  margin-top: 8px !important;
}
.center-list-fire {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-top: 10px;
  align-items: center;
}
.center-list-fire img {
  width: 14px;
  height: 14px;
}
.profit {
  margin-top: 151px;
}
.profit img:nth-child(1) {
  width: calc(100% - 102px);
}
.profit div {
  width: 305px;
  background: linear-gradient(180deg, #fff7e8 0%, #ffffff 100%);
  box-shadow: inset 0px 2px 0px 0px #ffffff;
  border-radius: 15px;
  margin: -3px auto 0;
  padding-bottom: 30px;
}
.profit div img {
  margin-top: -18px;
}
.profit div img:nth-child(1){
    width: 145px;
}
.profit div p:nth-child(2) {
  font-size: 18px;
  font-family: DINOffcPro-Medi, DINOffcPro;
  font-weight: normal;
  color: #ff5675;
  margin-top: 24px;
}
.profit div p:nth-child(3) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff5675;
  margin-top: 8px;
}
.profit div p:nth-child(4) {
  width: 197px;
  height: 44px;
  background: linear-gradient(314deg, #ff5675 0%, #ff886d 100%);
  border-radius: 22px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px auto 0;
}
</style>
